.mail-container{
    /* background-color: whitesmoke; */
    width: 85vw;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}