.header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.navbar {
    padding: 0 50px;
    height: 70px;
    width: 95%;
    background-color: whitesmoke;
    display: flex;
    justify-content:space-between;
    align-items: center;
    gap: 15px;
    border-radius: 15px;
    z-index: 4;
}
.nav-logo{
    width: 100%;
}
.nav-logo-link {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 700;
    width: 100%;
    color: blueviolet;
}

.nav-links {
    font-size: 1.1rem;
}

.nav-list {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    list-style: none;
}

.nav-list-item{
    width: 70px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02);
}
.nav-list-item:hover{
    color: white;
    cursor: pointer;
    background-color: blueviolet;
    transition: all 0.5s ease;
}

.nav-list-item-link {
    color: inherit;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.nav-toggle-button{
    display: none;
}
.nav-toggle-button i{
    cursor: pointer;
    font-size: 1.5rem;
}
#toggle-sidebar{
    border: none;
}
.sidebar{
    background-color:whitesmoke;
    position: fixed;
    top: 15px;
    bottom: 0;
    right: -1000px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    transition: transform 0.5s ease-in-out;
    z-index: 4;
}
.sidebar.hidden{
    transform: translateX(1000px);
}
.sidebar.show{
    transform: translateX(-1000px);
}
.sidebar-account-info-name{
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    justify-content: center;
}
.sidebar-list{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    gap: 20px;
    height: 75%;
}

.sidebar-list-item-link{
    text-decoration: none;
    color: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar-logout-button{
    border: none;
    width: 90px;
    height: 60px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1rem;
    margin: 15px;
}
.fa-person-through-window{
    font-size: 1.5rem;
}
.sidebar-logout:hover{
    background-color: blueviolet;
    transition: all 0.5s ease;
}
@media (max-width: 650px) {
    .nav-toggle-button {
        display: block;
    }
    .nav-list {
        display: none;
    }
}