.footer{
    color: white;
    background-color: #233959;
    position: fixed;
    bottom: 0;
    height: 40px;
    width: 100%;
    /* margin-top: clamp(100%); */
}
.footer-content{
    padding: 10px;
    text-align: center;
}