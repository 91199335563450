.loading-container{
  position: fixed;
  text-align: center;
  top: 40%;
  height: 100px;
  width: 100%;
}
.loading {
  height: 100px;
  width: 100px;
}
