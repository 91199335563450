.composer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width:90vw;
}
.editor{
    border: 2px solid rgb(165, 155, 155, 0.2);
    border-radius: 5px;
}
.continue-button{
    width: 70px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gold;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.continue-button:hover{
    cursor: pointer;
    color: white;
    background-color:blueviolet;
    transition: all 0.5s ease;
    box-shadow: none;
}

.rdw-editor-main{
  border: 1px solid #F1F1F1;
  padding: 0 20px;
  min-height: 40vh;
  border-radius: 5px;
  cursor: text;
}