.modal{
    position: fixed;
    /* background-color: rgba(0,0,0,0.2); */
    width: 100vw;
    height: 100vh;
    top: -3000px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.6s ease-in-out;
}
.modal.hidden{
    transform: translateY(-3000px);
}
.modal.show{
    transform: translateY(3000px);
}
.modal-content{
    background-color: whitesmoke;
    color: black;
    height: 40%;
    width: 85%;
    border-radius: 10px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-evenly;
}
.modal-message{
    text-align: center;
}
.fa-circle-exclamation{
    font-size: 4rem;
    color:red;
}
.modal-message h6{
    color: red;
}
.fa-envelope{
    font-size: 4rem;
    color:darkcyan;
}
.fa-paperclip{
    font-size: 4rem;
    color:darkcyan;
}
.modal-input{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal-text-input{
    height: 100%;
    width: 265px;
    padding: 5px;
    text-indent: 5px;
}

.modal-file-input input{
    display: none;
}
.modal-input h6{
    margin: 5px;
}
.modal-file-input label{
    padding: 4px;
    background-color:chocolate;
    color: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}
.fa-upload{
    font-size: 1.1rem;
}
.modal-buttons{
    width: 60%;
    max-width: 50%;
    height: 40px;
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.modal-button{
    width: 70px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.modal-button:hover{
    color: white;
    cursor: pointer;
    background-color:blueviolet;
    transition: all 0.5s ease;
    box-shadow: none;
}
.modal-button-close{
    background-color:cornflowerblue;
}
.modal-button-continue{
    background-color: gold;
}
