.not-found{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.not-found a{
    text-decoration: none;
    color: black;
}
.not-found img{
    width: 100%;
}