@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  /* background-color: '#FFFAF0'; */
}
.content{
  width: 100%;
  height: 100%;
  /* padding: 25px; */
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}